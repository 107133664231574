import React from "react";
import { useTranslation } from 'react-i18next'
import { socialMedia } from "../home";

import './experience.scss'

const Experience = () => {
    const { t } = useTranslation()

    return (

        <div className="experience center-child">
            <div className="container">
                <h1>My Work Experience</h1>
                <div className="table">

                    <div className="tile">
                        <div className="left">
                            <h3>Self employed</h3>
                            <p>Sep 2017 - Nov 2021</p>
                        </div>
                        <div className="divider">
                            <div className="circle"></div>
                            <div className="line"></div>
                        </div>
                        <div className="right">
                            <h3>Python Developer</h3>
                            <p>Pellentesque in sed mi, etiam. Ultrices tincidunt nulla mauris tellus id molestie vivamus enim. Ut porttitor consequat eu dui proin fringilla porta.</p>
                        </div>
                    </div>
                    <div className="tile">
                        <div className="left">
                            <h3>Self employed</h3>
                            <p>Sep 2017 - Nov 2021</p>
                        </div>
                        <div className="divider">
                            <div className="circle"></div>
                            <div className="line"></div>
                        </div>
                        <div className="right">
                            <h3>Python Developer</h3>
                            <p>Pellentesque in sed mi, etiam. Ultrices tincidunt nulla mauris tellus id molestie vivamus enim. Ut porttitor consequat eu dui proin fringilla porta.</p>
                        </div>
                    </div>
                    <div className="tile">
                        <div className="left">
                            <h3>Self employed</h3>
                            <p>Sep 2017 - Nov 2021</p>
                        </div>
                        <div className="divider">
                            <div className="circle"></div>
                            <div className="line"></div>
                        </div>
                        <div className="right">
                            <h3>Python Developer</h3>
                            <p>Pellentesque in sed mi, etiam. Ultrices tincidunt nulla mauris tellus id molestie vivamus enim. Ut porttitor consequat eu dui proin fringilla porta.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default Experience;