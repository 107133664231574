import React from 'react'
import './projects.scss'

import { projects } from '../home'


const Projects = () => {
    return (
        <div className="projects center-child">
            <div className="container">
                <h1 className='title'>
                    Some things I've built
                    <img className='arrow1' src="/assets/resources/pointing_arrow1.svg" alt="" />
                </h1>
                <p className='subtitle'>I am just starting so I don’t have that many things to show but here are some of my projects I think are worth mentioning.</p>

                {projects.map((project, index) => (
                    <React.Fragment key={index}>
                        <div className='project'>
                            <h3>{project.title}</h3>
                            <div className={`tile ${index % 2 === 1 ? "reversed" : ""} `}>
                                <div className="img-wrapper">
                                    <img src={`/assets/resources/${project.image}`} alt="" />

                                </div>
                                <div className="divider"></div>
                                <div className="main">
                                    <div>
                                        <h4>Featured</h4>
                                        {project.description.map((description, index) => (
                                            <p key={index}>{description}</p>
                                        ))}
                                    </div>

                                    <div className="bottom-bar">
                                        <p>made in <strong>
                                            {project.technologies.map((technology, index) => (
                                                <span key={index}>
                                                    <span>{technology}</span>
                                                    {(index >= 0 && index < project.technologies.length - 1) && <span>, </span>}
                                                </span>
                                            ))}
                                        </strong></p>
                                        <a className='btn' href={project.link} target="_blank">
                                            <img src="/assets/resources/download_icon.svg" alt="" />
                                            <span>Download</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {index === 0 &&
                            <div className="blob-wrapper">
                                <img className='b10' src="/assets/resources/b10.svg" alt="" />
                            </div>
                        }
                    </React.Fragment>


                ))}

                <h3 className='secret-title'>...and also this website 🤫
                    <img className='arrow2' src="/assets/resources/pointing_arrow2.svg" alt="" />

                </h3>

            </div>
        </div>
    )
}

export default Projects
