import React from "react";
import { useTranslation } from 'react-i18next'

import './story.scss'

const Story = () => {
    const { t } = useTranslation()

    return (<div className="story center-child">

        <div className="container">
            <img className='b4' src="/assets/resources/b4.svg" alt="" />
            <h2>Hi, I’m Matěj. Nice to meet you.</h2>
            <p>Pellentesque pellentesque vulputate pharetra nisl mus. Sed et gravida pellentesque sed ut turpis. Vitae feugiat volutpat gravida id tellus justo, lorem fermentum. Sed convallis blandit dictum est vestibulum sed turpis fermentum. Nam etiam varius sollicitudin volutpat ornare. Arcu felis nulla senectus bibendum faucibus magna. Justo, massa arcu tristique felis et, a maecenas augue. Dignissim iaculis.
            </p>
            <a className="styled" href="#">{"how it all started >"}</a>
        </div>
    </div>)
}


export default Story;