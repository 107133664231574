import React, { useEffect, useRef } from 'react'

import './up_arrow.scss'

const UpArrow = () => {
    const arrowRef = useRef(null)

    useEffect(() => {
        const handleScroll = event => {
            if (window.scrollY > window.innerHeight) {
                arrowRef.current.classList.remove('hidden')
            } else {
                arrowRef.current.classList.add('hidden')
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <a href='#root' className='up-arrow hidden' ref={arrowRef}>
            <svg viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 16L15 3L28 16" stroke="currentcolor" strokeWidth="5" strokeLinecap="round" />
            </svg>
        </a>
    )
}

export default UpArrow;
