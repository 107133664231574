import React from 'react'
import './contact.scss'

import { HighBentWave } from '../../../components/wave/wave'

const Contact = () => {
    return (
        <>
            <HighBentWave color='black' />
            <div className="contact center-child" id='contact'>

                <div className="container">
                    <h2 className='title'>Get in touch with me ✌</h2>
                    <div className="grid">

                        <div className="text">
                            <p className='subtitle'>I’m interested in freelance opportunities – especially ambitious or large projects. However, if you have other request or question, don’t hesitate to use the form.</p>
                            <div className="wrap">
                                <div className="paper-plane">
                                    <div className="trace">
                                        <svg width="566" height="139" viewBox="0 0 566 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M-380.5 7.49966C-112 202 384.066 152.828 344.962 25.9556C325.327 -37.7515 217.873 32.9791 334.37 104.242C427.568 161.253 537.58 106.419 565.547 70.0066" stroke="white" strokeLinejoin="round" strokeDasharray="17 17" />
                                        </svg>
                                    </div>
                                    <div className="plane">
                                        <img src="/assets/resources/paper_plane.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form">
                            <form action="">
                                <div className="prompt">
                                    <p>Name</p>
                                    <input type="text" id='name' />
                                </div>
                                <div className="prompt">
                                    <p>Email</p>
                                    <input type="email" id='email' />
                                </div>
                                <div className="prompt">
                                    <p>Message</p>
                                    <textarea id='message' />
                                </div>
                                <button type="submit" id='submit'>send a message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Contact;