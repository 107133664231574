import React from "react";
import { socialMedia } from "../pages/home/home";

import './footer.scss'


const Footer = () => {

    return (
        <footer className="center-child">
            <div className="container">
                <div className="blobs-left">
                    <img className="b16" src="/assets/resources/b16.svg" alt="" />
                    <img className="b17" src="/assets/resources/b17.svg" alt="" />
                </div>
                <ul className="links">
                    {socialMedia.map(site => (
                        site.show_in_footer &&
                        <li key={site.name}>
                            <a href={site.url} target="_blank" rel="noopener noreferrer" key={site.name}>{site.name}</a>
                        </li>
                    ))}
                </ul>
                <div className="blob-right">
                    <img className="b18" src="/assets/resources/b18.svg" alt="" />
                </div>
            </div>

            <div className="bottom-bar">
                <p>✌ handcrafted by me 🤙</p>
            </div>

        </footer>
    )
}

export default Footer;