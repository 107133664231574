import React from "react";
import { useTranslation } from 'react-i18next'
import { expenses } from "../home";

import './expenses.scss'

const Expenses = () => {
    const { t } = useTranslation()
    return (
        <div className="expenses">
            <div className="container">
                {expenses.map(tile => (
                    <div className="tile" key={tile.title}>
                        <div className="main">
                            <img className="icon" src={`/assets/resources/${tile.icon}`} alt="" />
                            <h3 className="title">{tile.title}</h3>
                            <p className="description">{tile.description}</p>
                        </div>
                        <a className="link styled" href={tile.anchor}>{"more >"}</a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Expenses;