import React from 'react'
import './quote.scss'

import DownArrows from '../../../components/down_arrows/down_arrows'
import Wave from '../../../components/wave/wave'


const QuoteMark = ({ color = "red" }) => {
    return (
        <div className="mark">
            <svg viewBox="0 0 192 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M96.2496 0.596685H54.6628L19.9256 43.162C9.65124 55.8826 0.84463 70.5603 0.84463 86.2165C0.84463 112.147 18.4579 126.825 39.4959 126.825C61.0231 126.825 77.6579 111.169 77.6579 89.1521C77.6579 71.5388 67.3835 59.3074 53.195 55.3934L96.2496 0.596685ZM191.655 0.596685H150.068L115.331 43.162C105.056 55.8826 96.2496 70.5603 96.2496 86.2165C96.2496 112.147 113.863 126.825 134.901 126.825C156.428 126.825 173.063 111.169 173.063 89.1521C173.063 71.5388 162.788 59.3074 148.6 55.3934L191.655 0.596685Z" fill={color} />
            </svg>
        </div>
    )
}


const Quote = () => {
    return (
        <>
            <Wave color='black' />
            <div className="quote center-child">
                <div className="outer-container">

                    <div className="main">
                        <div className='mark-wrapper'>
                            <QuoteMark />
                            <QuoteMark />
                        </div>
                        <div>
                            <h1>First, solve the problem.<br />
                                Then, write the code.</h1>
                            <p>- John Johnson</p>
                        </div>
                        <div className='mark-wrapper'>
                            <div className="flipped">
                                <QuoteMark />
                                <QuoteMark />
                            </div>
                        </div>
                    </div>

                    <div className="blob-section center-child">
                        <div className="container">

                            <div className="blobs">
                                <img className="b8" src="/assets/resources/b8.svg" alt="" />
                                <img className="b9" src="/assets/resources/b9.svg" alt="" />
                            </div>
                            <DownArrows />

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Quote
