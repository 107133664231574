import React from 'react'
import './down_arrows.scss'

const DownArrows = (props) => {
    return (
        <div className={"down-arrows" + (props?.small ? " small" : "")} style={{ padding: `${props?.noPadding ? "0px" : "3.5rem "}` }}>
            <img src="/assets/resources/down_arrows.svg" alt="" />
        </div>
    )
}

export default DownArrows