import React from "react";
import { useTranslation } from 'react-i18next'

import Landing from './landing/landing'
import Expenses from './expenses/expenses'
import Story from './story/story'
import Technologies from './technologies/technologies'
import Experience from "./experience/experience"
import Quote from './quote/quote'
import Projects from './projects/projects'
import Certifications from './certifications/certifications'
import Contact from './contact/contact'

import DownArrows from "../../components/down_arrows/down_arrows";
import UpArrow from "../../components/up_arrow/up_arrow";


// -----------------------------------------
// -----------------------------------------

export const socialMedia = [
    {
        name: 'Facebook',
        text: 'neco',
        url: 'https://www.facebook.com/matej.michalek',
        icon: 'facebook.svg',
        show_in_landing: true,
        show_in_footer: false
    },
    {
        name: 'LinkedIn',
        text: 'coming soon',
        url: 'https://www.linkedin.com/in/matej-michalek-a8b8a817b/',
        icon: 'linkedin.svg',
        show_in_landing: true,
        show_in_footer: true,
    },
    {
        name: 'Instagram',
        text: '@michalek_matej',
        url: 'https://www.instagram.com/michalek_matej/',
        icon: 'instagram.svg',
        show_in_landing: true,
        show_in_footer: false,
    },
    {
        name: 'GitHub',
        text: 'matejmichalek2004',
        url: 'https://github.com/matejmichalek2004',
        icon: null,
        show_in_landing: false,
        show_in_footer: true,
    },
    {
        name: 'Email',
        text: 'info@matejmichalek.ml',
        url: 'mailto:info@matejmichalek.ml',
        icon: null,
        show_in_landing: false,
        show_in_footer: true,
    }
]

// -----------------------------------------
// -----------------------------------------


export const expenses = [
    {
        title: 'UX Design',
        description: 'Lorem egestas fermentum cras nunc eget facilisis. Sapien tincidunt velit arcu egestas commodo id enim pretium platea. Turpis aliquam purus malesuada condimentum.',
        icon: "tile-icon1.svg",
        anchor: '#',
    },
    {
        title: 'Web Design',
        description: 'Consequat suscipit lacus est sem mauris. Ultricies velit mauris sed sit fringilla sed pretium nulla. Ullamcorper massa praesent vel, sed praesent dis faucibus ut. Luctus cras est sed elementum.',
        icon: "tile-icon2.svg",
        anchor: '#',
    },
    {
        title: 'Software Development',
        description: 'Lorem egestas fermentum cras nunc eget facilisis. Sapien tincidunt velit arcu egestas commodo id enim pretium platea. Turpis aliquam purus malesuada condimentum.',
        icon: "tile-icon3.svg",
        anchor: '#',
    }


]


// -----------------------------------------
// -----------------------------------------

export const technologies = [
    {
        title: 'Desktop apps',
        icon: 'desktop.svg',
        projects: 12,
        technologies: [
            {
                title: 'Python',
                description: 'Elit purus est, facilisi semper habitasse massa mauris morbi tortor. Lectus quis augue ullamcorper at. Sit fames commodo vehicula volutpat. Auctor vulputate.'
            },
            {
                title: 'Java',
                description: 'Elit purus est, facilisi semper habitasse massa mauris morbi tortor. Lectus quis augue ullamcorper at. Sit fames commodo vehicula volutpat. Auctor vulputate.'
            }
        ]
    },
    {

        title: 'Mobile apps',
        icon: 'phone.svg',
        projects: 6,
        technologies: [
            {
                title: 'Flutter',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            }
        ]
    },
    {

        title: 'Web development',
        icon: 'internet.svg',
        projects: 8,
        technologies: [
            {
                title: 'HTML',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            },
            {
                title: 'CSS',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            },
            {
                title: 'JavaScript',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            },
            {
                title: 'React',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            },
            {
                title: 'Node.js',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. In eum commodi, rerum corrupti repudiandae pariatur tenetur minus est ipsum ullam.'
            },
        ]
    },
    {

        title: 'Scripting',
        icon: 'tools.svg',
        projects: 23,
        technologies: [
            {
                title: 'Python',
                description: 'Elit purus est, facilisi semper habitasse massa mauris morbi tortor. Lectus quis augue ullamcorper at. Sit fames commodo vehicula volutpat. Auctor vulputate.'
            },
            {
                title: 'Bash',
                description: 'Elit purus est, facilisi semper habitasse massa mauris morbi tortor. Lectus quis augue ullamcorper at. Sit fames commodo vehicula volutpat. Auctor vulputate.'
            },

        ]
    }
]

// -----------------------------------------
// -----------------------------------------

export const projects = [
    {
        title: 'ID Wallet',
        description: [
            'Simple app that allows you to securely manage your identity papers such as your identity card, passport, driver licence, credit cards etc. on your phone.',
            'All data is safely encrypted and always offline.',
            'Saved me a few times personally 😅'
        ],
        technologies: [
            'Flutter'
        ],
        image: 'id_wallet.png',
        link: 'https://play.google.com/store/apps/details?id=com.idwallet'
    },
    {
        title: 'Key Hunt',
        description: [
            'Key Hunt is a 2D Mario-style game, where you must collect keys and avoid traps to advance to the next level. There are multiple levels to play. ',
            'It has a custom level maker so you can create your own challenges. ',
            'Created as a school project.'
        ],
        image: 'id_wallet.png',
        technologies: [
            'C# WPF'
        ],
        link: 'https://play.google.com/store/apps/details?id=com.idwallet'
    },
]

// -----------------------------------------
// -----------------------------------------

export const certificates = [
    {
        title: 'IT Essentials: PC Hardware and Software',
        creator: 'By Cisco Networking Academy',
        date: 'Jul 2021',
        download: '/assets/certificates/it-essentials.pdf',
        website: 'https://www.netacad.com/courses/os-it/it-essentials',
        description: [
            'Install, configure, and troubleshoot computers and mobile devices.',
            'Identify common security threats like phishing and spoofing.',
            'Develop critical thinking and problem-solving skills using both real equipment and Cisco Packet Tracer.'
        ]
    },
    {
        title: 'CCNA: Introduction to Networks',
        creator: 'By Cisco Networking Academy',
        date: 'upcoming',
        download: '/assets/certificates/ccna_introduction_to_networks.pdf',
        website: 'https://www.netacad.com/courses/networking/ccna-introduction-networks',
        description: [
            'Install, configure, and troubleshoot computers and mobile devices.',
            'Identify common security threats like phishing and spoofing.',
            'Develop critical thinking and problem-solving skills using both real equipment and Cisco Packet Tracer.'
        ]
    },
    {
        title: 'NDG Linux Essentials',
        creator: 'By Cisco Networking Academy in collaboration with NDG',
        date: 'upcoming',
        download: '/assets/certificates/ndg_linux_essentials.pdf',
        website: 'https://www.netacad.com/courses/os-it/ndg-linux-essentials',
        description: [
            'Install, configure, and troubleshoot computers and mobile devices.',
            'Identify common security threats like phishing and spoofing.',
            'Develop critical thinking and problem-solving skills using both real equipment and Cisco Packet Tracer.'
        ]
    },

]

// -----------------------------------------
// -----------------------------------------



export default function Home() {
    // const { t } = useTranslation()

    return (
        <>
            <Landing />
            <Expenses />

            <DownArrows small />

            <Story />
            <Technologies />

            {/* <DownArrows /> */}

            <Experience />
            <Quote />
            <Projects />
            <Certifications />
            <Contact />


            <UpArrow />


        </>
    )
}