import React, { useState, useEffect, useRef, createRef } from "react";
import { useTranslation } from 'react-i18next'
import { technologies } from "../home.js"
import DownArrows from "../../../components/down_arrows/down_arrows";
import Wave from "../../../components/wave/wave.js";


import './technologies.scss'


const Technologies = () => {
    const { t } = useTranslation()
    const [activeSection, setActiveSection] = useState(0)

    let sectionRefs = []
    let technologiesRefs = []

    function tileClick(index) {
        sectionRefs.forEach(ref => {
            ref.current.classList.add('hidden')
        })
        setActiveSection(index)
    }

    useEffect(() => {
        for (let section of technologies) {
            sectionRefs.push(createRef(null))
            for (let tech of section.technologies) {
                technologiesRefs.push(createRef(null))
            }
        }
    }, [])



    return (
        <>
            <Wave color="" className="wave-technologies" />
            <div className="technologies center-child">
                <div className="container">
                    <img className="b5" src="/assets/resources/b5.svg" alt="" />
                    <h1>Technologies I Use</h1>

                    <div className="table">

                        <div className="sections">

                            {technologies.map((section, index) => (
                                <div className={`tile  ${index === activeSection && "selected"}`} onClick={() => tileClick(index)} ref={sectionRefs[index]} key={index}>
                                    <img className="icon" src={`/assets/resources/${section.icon}`} alt="" />
                                    <div>
                                        <h3 className="title">{section.title}</h3>
                                        <p className="project-count">{section.projects + " projects"}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="descriptions">
                            {technologies[activeSection].technologies.map((technology, index) => (
                                <div className="description" ref={technologiesRefs[index]} key={index}>
                                    <h3>{technology.title}</h3>
                                    <p>{technology.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="design">
                        <DownArrows />
                        <div className="blobs">
                            <img className="b7" src="/assets/resources/b7.svg" alt="" />
                            <img className="b6" src="/assets/resources/b6.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default Technologies;