import React, { useEffect, createRef } from 'react'
import './wave.scss'

const Wave = ({ color = 'red', className = "" }) => {
    const waveRef = createRef(null)

    useEffect(() => {
        waveRef.current.style.marginTop = -waveRef.current.clientHeight + "px" //move wave 100% of its height up to cover background space
    })


    return (
        <div className={"spacing-wave " + className} ref={waveRef}>
            <svg height="65" viewBox="0 0 1440 65" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-34 132V0C-34 0 338.123 62.9614 715.248 62.9614C1116.38 62.9614 1474 0 1474 0V132H-34Z" fill={color} />
            </svg>
        </div>

    )
}

export default Wave




export const HighBentWave = ({ color = 'red', className = "" }) => {
    const waveRef = createRef(null)

    useEffect(() => {
        console.log(waveRef.current.clientHeight)
        waveRef.current.style.marginTop = -waveRef.current.clientHeight + "px" //move wave 100% of its height up to cover background space
    })


    return (
        <div className={"spacing-wave " + className} ref={waveRef}>
            <svg height="280" viewBox="0 0 1440 280" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-10.4421 163.7C550.653 426.811 1212.4 164.713 1473.14 0.774414L1453.5 384L-10.4422 384L-10.4421 163.7Z" fill={color} />
            </svg>

        </div>

    )
}


