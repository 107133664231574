import React from "react";
import { useTranslation } from 'react-i18next'
import { socialMedia } from "../home";

import './landing.scss'

const Landing = () => {
    const { t } = useTranslation()

    var functionforscroll = function (id) {
        window.scrollTo(0, document.querySelector(id).offsetTop - 85);
    }

    return (
        <div className="landing center-child">
            {/* <img className="b3" src="/assets/resources/b3.svg" alt="" /> */}

            <div className="outer outer-container">

                <div className="social-sites">
                    {socialMedia.map(site => (
                        site.show_in_landing &&
                        <div className="tile" key={site.name}>
                            <img className="icon" src={`/assets/resources/${site.icon}`} alt="" />
                            <a className="link" target="_blank" rel="noreferrer" href={site.url}><p>{site.text}</p></a>
                        </div>
                    ))}
                </div>

                <img className="b3" src="/assets/resources/b3.svg" alt="" />


                <div className="container">

                    <div className="main-content">
                        <div className="title-wrapper">
                            <div className="vertical-line"></div>
                            <div>
                                <h2 className="welcome">{t('landing_welcome')}</h2>
                                <h1 className="name">{t('landing_name')}</h1>
                            </div>
                        </div>
                        <p className="subtitle">{t('landing_subtitle')}</p>
                        {/* <button className="contact-btn">{t('landing_contact_btn')}</button> */}
                        {/* <div style={{ height: "1.2rem" }}></div> */}
                        <button onClick={() => functionforscroll('#contact')} className="contact-btn">{t('landing_contact_btn')}</button>
                    </div>

                    <div className="photo-section">
                        <img className="b1" src="/assets/resources/b1.svg" alt="" />
                        <img className="portrait" src="/assets/resources/portrait.png" alt="portrait" />
                        <img className="b2" src="/assets/resources/b2.svg" alt="" />
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Landing;